import React, {useEffect, useRef} from "react"
import Layout from "../../components/interior/layout-int"
import SEO from "../../components/seo"
import { Helmet } from "react-helmet"
const InteriorPage = (props) => {
    const isMountedComponent = useRef(true)
useEffect(() => {
        if (isMountedComponent.current) {
            if (typeof window !== "undefined") {
                window.funciones()
            }
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Interior"/>
            <Helmet>
                <script async src="https://cse.google.com/cse.js?cx=001810222165709585812:ryj3ejzoif4" ></script>
            </Helmet>
            <>
            <section className="parallax bg-extra-dark-gray header-int" data-parallax-background-ratio="0.5"
                     style={{backgroundImage: 'url("/images/lca/header1.jpg")'}}>
                <div className="opacity-extra-medium bg-extra-dark-gray"/>
                <div className="container">
                    <div className="header row align-items-stretch justify-content-center small-screen">
                        <div
                            className="col-12 col-xl-6 col-lg-7 col-md-8 page-title-extra-small text-center d-flex justify-content-center flex-column">
                            <h1 className="alt-font text-white opacity-6 margin-20px-bottom">What we offers</h1>
                            <h2 className="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30 no-margin-bottom">we
                                are a creative design studio</h2>
                        </div>
                        <div className="down-section text-center"><button aria-label="down-section"
                                                                     className="section-link"><i
                            className="ti-arrow-down icon-extra-small text-white bg-transparent-black padding-15px-all xs-padding-10px-all border-radius-100"/></button>
                        </div>
                    </div>
                </div>
            </section>

            <section id="down-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="gcse-search"></div>
                        </div>
                    </div>
                </div>

            </section>
        </>
        </Layout>
    )
}

export default InteriorPage
